// src/components/ShelterCard.tsx
import React from 'react';
import { Shelter } from '../models/Shelter';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  Box,
} from '@mui/material';

interface ShelterCardProps {
  shelter: Shelter;
}

const ShelterCard: React.FC<ShelterCardProps> = ({ shelter }) => {
  const {
    name,
    type,
    address,
    contactInfo,
    description,
    ownerName,
    cost,
  } = shelter;

  return (
    <Card sx={{ margin: '16px auto', borderRadius: '12px' }}>
      <CardHeader
        title={name}
        subheader={`${type.charAt(0).toUpperCase() + type.slice(1)} Shelter`}
      />
      <Divider />
      <CardContent>
        <Box mb={2}>
          <Typography variant="body2" color="textSecondary">
            <strong>Address:</strong> {address}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Contact:</strong> {contactInfo.phone || 'N/A'},{' '}
            {contactInfo.email || 'N/A'}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Description:</strong> {description || 'No description provided.'}
          </Typography>
        </Box>

        {type === 'public' && ownerName && (
          <Typography variant="body2" color="textSecondary">
            <strong>Public Owner:</strong> {ownerName}
          </Typography>
        )}

        {type === 'private' && (
          <>
            {ownerName && (
              <Typography variant="body2" color="textSecondary">
                <strong>Owner:</strong> {ownerName}
              </Typography>
            )}
            {cost !== undefined && (
              <Typography variant="body2" color="textSecondary">
                <strong>Cost:</strong> {cost === 0 ? 'Free' : `$${cost}`}
              </Typography>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ShelterCard;
