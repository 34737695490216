// src/components/Tabs.tsx
import React from 'react';
import './Tabs.css';

type TabType = 'public' | 'private';

interface TabsProps {
  activeTab: TabType;
  onTabChange: (tab: TabType) => void;
}

const Tabs: React.FC<TabsProps> = ({ activeTab, onTabChange }) => {
  return (
    <div className="tabs">
      <button
        className={activeTab === 'public' ? 'active' : ''}
        onClick={() => onTabChange('public')}
      >
        Public
      </button>
      <button
        className={activeTab === 'private' ? 'active' : ''}
        onClick={() => onTabChange('private')}
      >
        Private
      </button>
    </div>
  );
};

export default Tabs;
