// src/components/ShelterList.tsx
import React from 'react';
import ShelterCard from '../ShelterCard';
import { Shelter } from '../../models/Shelter';

interface ShelterListProps {
  shelters: Shelter[];
}

const ShelterList: React.FC<ShelterListProps> = ({ shelters }) => (
  <div className="shelter-list">
    {shelters.map((shelter) => (
      <ShelterCard key={shelter.id} shelter={shelter} />
    ))}
  </div>
);

export default ShelterList;
