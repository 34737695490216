// src/App.tsx
import React, { useEffect, useState } from 'react';
import { Shelter } from './models/Shelter';
import ShelterList from './components/shelterlist/ShelterList';
import Tabs from './components/tabs/Tabs';
import { Container, Typography } from '@mui/material'; // Import Container and Typography

const App: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'public' | 'private'>('public');
  const [shelters, setShelters] = useState<Shelter[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setLoading(true);
      try {
        const filePath =
          activeTab === 'public'
            ? './data/public_shelters.json'
            : './data/private_shelters.json';
        const response = await fetch(filePath);
        const data: Shelter[] = await response.json();
        data.sort((a, b) => a.name.localeCompare(b.name));
        if (isMounted) {
          setShelters(data);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching shelters:', error);
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [activeTab]);

  return (
    <Container maxWidth="md"> {/* Wrap content in Container */}
      <Typography variant="h4" component="h1" gutterBottom>
        Open Doors Lebanon
      </Typography>
      <Tabs activeTab={activeTab} onTabChange={setActiveTab} />
      {loading ? (
        <Typography variant="body1">Loading shelters...</Typography>
      ) : (
        <ShelterList shelters={shelters} />
      )}
    </Container>
  );
};

export default App;
